// 基本信息存储
import Vue from "vue";
import { axios } from "@/utils/http";
import { Toast } from "vant";
// Token
import { SET_TOKEN, GET_TOKEN, REMOVE_TOKEN } from "@/utils/token";
// 用户信息
import { SET_USERINFO, GET_USERINFO, REMOVE_USERINFO } from "@/utils/userInfo";
// 接口
import { postLogOutAPI } from "@/services/user";

const user = {
  // 命名空间
  namespaced: true,
  state: {
    token: GET_TOKEN(),
    userInfo: JSON.parse(GET_USERINFO()), // 用户信息
  },

  getters: {},

  mutations: {
    // 保存用户信息
    saveUserInfo(state, payload) {
      // eslint-disable-next-line no-console
      console.log("===>", payload);
      state.token = payload.token;
      state.userInfo = payload.loginUser;
      // 本地存储存储一份
      SET_TOKEN(payload.token);
      SET_USERINFO(payload.loginUser);
    },

    // 清除用户信息
    removeUserInfo(state) {
      state.token = REMOVE_TOKEN();
      state.userInfo = REMOVE_USERINFO();
    },
  },

  actions: {
    // 登录
    upDataUserInfo({ commit }, payload) {
      commit("saveUserInfo", payload);
    },

    // 退出登录
    async logOut({ commit }) {
      const res = await postLogOutAPI();
      if (res.code == 200) {
        commit("removeUserInfo");
        // 清除定位
        localStorage.removeItem("userLocation");
      }
    },
  },
};

export default user;
