// 模块路由
let moduleRoutes = [];

let routerContext = require.context("./modules/", true, /\.js$/);

routerContext.keys().forEach((filePath) => {
  const routerModule = routerContext(filePath);
  moduleRoutes = moduleRoutes.concat(routerModule.default || routerModule);
});

// 基础路由
const baseRoutes = [
  {
    path: "",
    redirect: () => "/home",
  },
  {
    path: "/home",
    name: "home",
    meta: {
      title: "首页",
      requireAuth: false,
    },
    component: () => import("@/views/home/index.vue"),
  },
  {
    path: "/map",
    name: "indoorMap",
    meta: {
      title: "",
      requireAuth: false,
    },
    component: () => import("@/views/talkingMap/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "登录",
      requireAuth: false,
    },
    component: () => import("@/views/logAndReg/login.vue"),
  },
  {
    path: "/forgetCode",
    name: "forgetCode",
    meta: {
      title: "重置密码",
      requireAuth: false,
    },
    component: () => import("@/views/logAndReg/forgetCode.vue"),
  },
  {
    path: "/registerInfo",
    name: "registerInfo",
    meta: {
      title: "注册",
      requireAuth: false,
    },
    component: () => import("@/views/logAndReg/registerInfo.vue"),
  },
  {
    path: "/resetAccount",
    name: "resetAccount",
    meta: {
      title: "重置密码",
      requireAuth: false,
    },
    component: () => import("@/views/logAndReg/resetAccount.vue"),
  },
  {
    path: "/user",
    name: "user",
    meta: {
      title: "我的",
      requireAuth: false,
    },
    component: () => import("@/views/user/user.vue"),
  },
  {
    path: "/user/editPassword",
    name: "editPassword",
    meta: {
      title: "修改密码",
      requireAuth: false,
    },
    component: () => import("@/views/user/editPassword.vue"),
  },
  {
    path: "/successPage",
    name: "successPage",
    meta: {
      title: "成功",
      requireAuth: false,
    },
    component: () => import("@/views/user/successPage.vue"),
  },
  {
    path: "*",
    name: "notFound",
    meta: {},
    component: () => import("@/views/other/404.vue"),
  },
];

moduleRoutes = [...moduleRoutes, ...baseRoutes];

export const routes = moduleRoutes;
