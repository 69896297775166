export default [
  // 订单列表
  {
    path: "/orderList",
    name: "orderList",
    meta: {
      title: "我的订单", 
      requiresAuth: true
    },
    component: () => import("@/views/order/orderList.vue") 
  }
];
