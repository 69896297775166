import { Toast } from "vant";
import wx from "weixin-js-sdk";
// app基础配置
const app = {
  state: {
    appConfig: {},
    isWeixin: false,
  },
  mutations: {
    updateAppConfig(state, appConfig) {
      state.appConfig = appConfig;
    },
    isWeixin(state, payload) {
      window.console.log("$store=>isWeixin", payload);
      state.isWeixin = payload;
    },
  },
  actions: {
    appConfigAction({ commit }, appConfig) {
      commit("updateAppConfig", appConfig);
    },
    // 跳转前往微信支付页面
    toWxPay({ state, commit }, { PayOrderId }) {
      if (!state.isWeixin) {
        Toast.fail("请在微信环境打开");
        return;
      }
      wx.miniProgram.navigateTo({
        url: "/pages/pay/pay?PayOrderId=" + PayOrderId,
      });
    },
  },
  getters: {},
};

export default app;
