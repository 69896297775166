export default [
  {
    path: "/publicService",
    name: "publicService",
    meta: {
      title: "公共服务",
      requiresAuth: false
    },
    component: () => import("@/views/w_publicServiceModule/index")
  },

  {
    path: "/yixinLine",
    name: "w_yixinLine",
    meta: {
      title: "驿行线路",
      requiresAuth: false
    },
    component: () => import("@/views/w_yixinLine/index")
  },
  {
    path: "/yixinLine/lineDetails",
    name: "lineDetails",
    meta: {
      title: "路线详情",
      requiresAuth: false
    },
    component: () => import("@/views/w_yixinLine/lineDetails")
  },
  {
    path: "/famousHotel",
    name: "famousHotel",
    meta: {
      title: "民宿宾馆",
      requiresAuth: false
    },
    component: () => import("@/views/w_famousHotel/index")
  },
  {
    path: "/famousHotel/hotelDetails",
    name: "hotelDetails",
    meta: {
      title: "民宿详情",
      requiresAuth: false
    },
    component: () => import("@/views/w_famousHotel/hotelDetails")
  },
  {
    path: "/photoList",
    name: "photoList",
    meta: {
      title: "商家相册",
      requiresAuth: false
    },
    component: () => import("@/views/tools/photoList.vue")
  },
  {
    path: "/famousHotel/roomDetails",
    name: "roomDetails",
    meta: {
      title: "房间详情",
      requiresAuth: false
    },
    component: () => import("@/views/w_famousHotel/roomDetails")
  },
  {
    path: "/famousHotel/verifyAndPay",
    name: "verifyAndPay",
    meta: {
      title: "确认支付",
      requiresAuth: true
    },
    component: () => import("@/views/w_famousHotel/verifyAndPay")
  },
  {
    path: "/paySucceed",
    name: "paySucceed",
    meta: {
      title: "支付成功",
      requiresAuth: true
    },
    component: () => import("@/views/w_famousHotel/paySucceed")
  },
  {
    path: "/hotelOrder",
    name: "hotelOrder",
    meta: {
      title: "订单详情",
      requiresAuth: true
    },
    component: () => import("@/views/w_famousHotel/hotelOrder")
  },
  {
    path: "/fineFood",
    name: "fineFood",
    meta: {
      title: "餐饮美食",
      requiresAuth: false
    },
    component: () => import("@/views/w_fineFood/index")
  },
  {
    path: "/w_fineFood/detail",
    name: "finedetail",
    meta: {
      title: "商铺详情",
      requiresAuth: false
    },
    component: () => import("@/views/w_fineFood/foodDetails")
  },
  {
    path: "/w_fineFood/setmealDetails",
    name: "setmealDetails",
    meta: {
      title: "套餐详情",
      requiresAuth: false
    },
    component: () => import("@/views/w_fineFood/setmealDetails")
  },
  {
    path: "/w_fineFood/submitOrder",
    name: "submitOrder",
    meta: {
      title: "提交订单",
      requiresAuth: true
    },
    component: () => import("@/views/w_fineFood/submitOrder")
  },
  {
    path: "/w_fineFood/foodOrder",
    name: "foodOrder",
    meta: {
      title: "订单详情",
      requiresAuth: true
    },
    component: () => import("@/views/w_fineFood/foodOrder.vue")
  },

  //  蔬果采摘
  {
    path: "/fruitsPick/index",
    name: "fruitsPick",
    meta: {
      title: "蔬果采摘",
      requiresAuth: false
    },
    component: () => import("@/views/w_fruitsPick/index")
  },
  {
    path: "/fruitsPick/attractionsdetails",
    name: "attractionsdetails",
    meta: {
      title: "详情",
      requiresAuth: false
    },
    component: () => import("@/views/w_fruitsPick/attractionsdetails")
  },
  {
    path: "/fruitsPick/Setmeal",
    name: "fruitsPickSetmeal",
    meta: {
      title: "套餐详情",
      requiresAuth: false
    },
    component: () => import("@/views/w_fruitsPick/fruitsPickSetmeal")
  },
  {
    path: "/fruitsPick/submitOrder",
    name: "fruitsPicksubmitOrder",
    meta: {
      title: "提交订单",
      requiresAuth: true
    },
    component: () => import("@/views/w_fruitsPick/submitOrder")
  },
  {
    path: "/fruitsPick/OrderDetail",
    name: "fruitsOrder",
    meta: {
      title: "订单详情",
      requiresAuth: true
    },
    component: () => import("@/views/w_fruitsPick/fruitsOrder")
  },


  {
    path: "/fruitsPick/photoAibum",
    name: "fruitsPickphotoAlbum",
    meta: {
      title: "官方相册",
      requiresAuth: false
    },
    component: () => import("@/views/w_fruitsPick/photoAlbum")
  },
  {
    path: "/orderList/fruitsPickDetails",
    name: "fruitsPickDetails",
    meta: {
      title: "订单详情",
      requiresAuth: false
    },
    component: () => import("@/views/user/fruitsPickDetails")
  },

  //  ===================特色周边===================================
  {
    path: "/nearbyFeatures",
    name: "nearbyFeatures",
    meta: {
      title: "特色周边",
      requiresAuth: false
    },
    component: () => import("@/views/z_nearbyFeatures/index")
  },
  {
    path: "/nearbyFeatures/details",
    name: "nearbyFeaturesdetails",
    meta: {
      title: "景点详情",
      requiresAuth: false
    },
    component: () => import("@/views/z_nearbyFeatures/scenicDetails")
  },
  {
    path: "/nearbyFeatures/orderFilling",
    name: "nearbyFeaturesorderFilling",
    meta: {
      title: "填写订单",
      requiresAuth: false
    },
    component: () => import("@/views/z_nearbyFeatures/orderFilling")
  },
  {
    path: "/nearbyFeatures/addPerson",
    name: "nearbyFeaturesaddPerson",
    meta: {
      title: "新增出行人",
      requiresAuth: false
    },
    component: () => import("@/views/w_fruitsPick/photoAlbum")
  },
  //  ======================================================
  // {
  //   path: "/nearbyFeatures",
  //   name: "nearbyFeatures",
  //   meta: {
  //     title: "特色周边",
  //     requiresAuth: false
  //   },
  //   component: () => import("@/views/z_nearbyFeatures/index")
  // },
  // {
  //   path: "/nearbyFeatures/details",
  //   name: "nearbyFeaturesdetails",
  //   meta: {
  //     title: "景点详情",
  //     requiresAuth: false
  //   },
  //   component: () => import("@/views/z_nearbyFeatures/scenicDetails")
  // },
  // {
  //   path: "/nearbyFeatures/orderFilling",
  //   name: "nearbyFeaturesorderFilling",
  //   meta: {
  //     title: "填写订单",
  //     requiresAuth: false
  //   },
  //   component: () => import("@/views/z_nearbyFeatures/orderFilling")
  // }
  //  ======================================================
  // 发现港西
  {
    path: "/foundGangxi",
    name: "foundGangxi",
    meta: {
      title: "发现港西",
      requiresAuth: false
    },
    component: () => import("@/views/homeLists/foundGangxi/lists.vue")
  },
  // 发现港西
  {
    path: "/gangxiFlavor",
    name: "gangxiFlavor",
    meta: {
      title: "港西味道",
      requiresAuth: false
    },
    component: () => import("@/views/homeLists/gangxiFlavor/lists.vue")
  },
];
