// 工具
export default [
  {
    path: "/userSetting",
    name: "userSetting",
    meta: {
      title: "设置",
      requiresAuth: true
    },
    component: () => import("@/views/tools/userSetting.vue")
  },

  {
    path: "/affiche",
    name: "affiche",
    meta: {
      title: "公告详情",
      requiresAuth: false
    },
    component: () => import("@/views/tools/affiche.vue")
  },

  // 地图
  {
    path: "/addressMap",
    name: "addressMap",
    meta: {
      title: "服务线路",
      requiresAuth: true
    },
    component: () => import("@/views/tools/addressMap.vue")
  }
];
