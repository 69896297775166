/**
 * 工具类
 */

/**
 * 去掉对象为null或空字符串或undefined的值（key/value），一般用于筛选
 * @params {*}
 * @returns Object
 */
export function removeNullInObj(obj = {}) {
  if (!(typeof obj === "object")) {
    return false;
  }

  for (let k in obj) {
    if (obj[k] === undefined || obj[k] === "" || obj[k] === null) {
      delete obj[k];
    }
  }
  return obj;
}

/**
 * 判断是否是空对象
 * @param data
 * @returns {Boolean}
 */
export function isEmptyObject(data) {
  let b = false;
  if (isObject(data) && JSON.stringify(data) === "{}") {
    b = true;
  }
  return b;
}

/**
 * 判断是否是对象类型
 * @param data
 * @returns {Boolean}
 */
export function isObject(data) {
  return dataType(data) === "object";
}

/**
 * 判断是否是数组类型
 * @param data
 * @returns {Boolean}
 */
export function isArray(data) {
  return dataType(data) === "array";
}

/**
 * 获取数据类型
 * @param data
 * @returns {String}
 */
export function dataType(data) {
  const type = {
    "[object String]": "string",
    "[object Array]": "array",
    "[object Object]": "object",
    "[object Null]": "null",
    "[object Number]": "number",
    "[object Undefined]": "undefined",
    "[object Boolean]": "boolean",
    "[object Function]": "function",
  };
  const resType = Object.prototype.toString.call(data);
  return type[resType];
}

let exp = {};
exp.REG_EMAIL = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/; //邮箱
exp.REG_PHONE = /^\d{11}$/; //国内手机11位数
export { exp };

/**
 * 判断是否IOS环境
 * @returns Boolean
 */
export function isIOS() {
  let isIphone = navigator.userAgent.includes("iPhone");
  let isIpad = navigator.userAgent.includes("iPad");
  return isIphone || isIpad;
}

/**
 * 判断是否安卓环境
 * @returns Boolean
 */
export function isAndroid() {
  const u = navigator.userAgent;
  return u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
}

export function getFileType(str) {
  if (typeof str !== "string") {
    throw "参数类型错误";
  }
  let suffixName = str.substring(str.lastIndexOf("."));
  let imgType = [
    ".bmp",
    ".jpg",
    ".jpeg",
    ".jpe",
    ".jxr",
    ".png",
    ".tif",
    ".tiff",
    ".avif",
    ".xbm",
    ".pjp",
    ".svgz",
    ".ico",
    ".svg",
    ".jfif",
    ".webp",
    ".pjpeg",
    ".gif",
    ".iff",
    ".ilbm",
    ".ppm",
    ".pcx",
    ".xcf",
    ".xpm",
    ".psd",
    ".mng",
    ".sai",
    ".psp",
    ".ufo",
  ];
  let videoType = [
    ".mp4",
    ".3gp",
    ".avi",
    ".wmv",
    ".mpeg",
    ".mpg",
    ".mov",
    ".flv",
    ".swf",
    ".qsv",
    ".kux",
    ".mpg",
    ".rm",
    ".ram",
  ];
  let audioType = [
    ".mp3",
    ".mp2",
    ".mp1",
    ".wav",
    ".aif",
    ".aiff",
    ".au",
    ".ra",
    ".rm",
    ".ram",
    ".mid",
    ".rmi",
  ];
  let fileType = ["img", "video", "audio"];
  if (imgType.includes(suffixName)) {
    return fileType[0];
  }
  if (videoType.includes(suffixName)) {
    return fileType[1];
  }
  if (audioType.includes(suffixName)) {
    return fileType[2];
  }
  return false;
}

// 计算距离
// export function getUserLocation() {
//   return new Promise((resolve, reject) => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         position => {
//           const userLatitude = position.coords.latitude;
//           const userLongitude = position.coords.longitude;
//           resolve({ latitude: userLatitude, longitude: userLongitude });
//         },
//         error => {
//           reject("错误信息", error);
//         }
//       );
//     } else {
//       reject(
//         new Error(
//           "Geolocation is not supported by this browser. 此浏览器不支持地图定位"
//         )
//       );
//     }
//   });
// }

// export function calculateDistance(
//   userLatitude,
//   userLongitude,
//   { latitude, longitude }
// ) {
//   window.console.log("用户维度", userLatitude); // 用户维度
//   window.console.log("用户经度", userLongitude); // 用户纬度
//   window.console.log("接口维度", latitude); // 用户维度
//   window.console.log("接口经度", longitude); // 用户纬度
//   const earthRadius = 6371; // 地球半径，单位为公里
//   const dLat = degToRad(latitude - userLatitude);
//   const dLon = degToRad(longitude - userLongitude);

//   const a =
//     Math.sin(dLat / 2) * Math.sin(dLat / 2) +
//     Math.cos(degToRad(userLatitude)) *
//       Math.cos(degToRad(latitude)) *
//       Math.sin(dLon / 2) *
//       Math.sin(dLon / 2);

//   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
//   const distance = earthRadius * c * 1000; // 距离转换为米
//   // window.console.log(distance)
//   return distance;
// }

// function degToRad(deg) {
//   return deg * (Math.PI / 180);
// }

// utils.js

// 导入百度地图 API
// import BMap from "BMap";

// // 获取用户定位
// export function getUserLocation() {
//   return new Promise((resolve, reject) => {
//     const geolocation = new BMap.Geolocation();

//     geolocation.getCurrentPosition(function (result) {
//       if (this.getStatus() === BMap.StatusCodes.SUCCESS) {
//         const { longitude, latitude } = result.point;
//         resolve({ longitude, latitude });
//       } else {
//         reject(new Error("Failed to get user location"));
//       }
//     });
//   });
// }

// // 计算两个经纬度之间的距离
// export function calculateDistance(lat1, lon1, lat2, lon2) {
//   const radLat1 = (lat1 * Math.PI) / 180;
//   const radLat2 = (lat2 * Math.PI) / 180;
//   const a = radLat1 - radLat2;
//   const b = (lon1 * Math.PI) / 180 - (lon2 * Math.PI) / 180;
//   let distance =
//     2 *
//     Math.asin(
//       Math.sqrt(
//         Math.pow(Math.sin(a / 2), 2) +
//           Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)
//       )
//     ) *
//     6378137; // 地球半径，单位为米

//   return distance.toFixed(2); // 保留两位小数，并返回距离值
// }

// // 处理接口返回的位置数据，计算与用户位置的距离
// export function processLocations(userLocation, apiLocations) {
//   return apiLocations.map(location => {
//     const distance = calculateDistance(
//       userLocation.latitude,
//       userLocation.longitude,
//       location.latitude,
//       location.longitude
//     );

//     return { ...location, distance };
//   });
// }
