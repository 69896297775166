<template>
  <van-tabbar
    v-if="showGlobalFooter"
    active-color="#17CF6B"
    v-model="active"
    @change="onChange"
  >
    <!-- <van-tabbar-item
      v-for="(value, key) in barData"
      :key="key"
      :name="value.name"
      :icon="value.icon"
      >{{ value.label }}</van-tabbar-item
    > -->
    <van-tabbar-item v-for="(value, key) in barData" :key="key">
      <span>{{ value.label }}</span>
      <template #icon="props">
        <img :src="props.active ? value.active : value.inactive" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  watch: {
    $route(to, from) {
      const pathName = to.path;
      this.showGlobalFooter = Object.values(this.barData).some(
        (i) => i.path === pathName
      );
      if (this.showGlobalFooter) {
        this.active = Object.values(this.barData).findIndex(
          (i) => i.path === pathName
        );
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      showGlobalFooter: false,
      active: 0,
      // barData: {
      //   home: {
      //     label: "首页",
      //     icon: "home-o",
      //     name: "home",
      //     path: "/home",
      //   },
      //   user: {
      //     label: "我的",
      //     icon: "user-o",
      //     name: "user",
      //     path: "/user",
      //   },
      // },
      barData: [
        {
          label: "首页",
          name: "home",
          path: "/home",
          active: require("@/assets/img/home_hot.png"),
          inactive: require("@/assets/img/home.png"),
        },
        {
          label: "我的",
          name: "user",
          path: "/user",
          active: require("@/assets/img/my_hot.png"),
          inactive: require("@/assets/img/my.png"),
        },
      ],
    };
  },
  created() {
    const pathName = window.location.pathname;
    this.showGlobalFooter = Object.values(this.barData).some(
      (i) => i.path === pathName
    );
  },
  methods: {
    onChange(key) {
      const { path } = this.barData[key];
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped></style>
