import router from "@/router";

/**
 * 错误码管理
 */
import { Notify } from "vant";
export default err => {
    // 状态码判断
    if (err && err.response) {
        switch (err.response.status) {
            case 401:
                Notify({ type: "danger", message: "登录过期，请重新登录！" });
                router.push("/login");
                break;
            case 502:
                Notify({ type: "danger", message: "前方拥挤" });
                break;
            default:
                Notify({ type: "danger", message: "当前网络不稳定" });
        }
    } else {
        Notify({ type: "danger", message: "当前网络不稳定" });
    }
};
