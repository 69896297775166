// 用户登录接口
import request from "@/utils/http/request";

/**
 * 密码登录
 */
export const postLoginAPI = (username, password) => {
  return request({
    url: "/account/login",
    method: "post",
    data: {
      username,
      password,
    },
  });
};

/**
 * 获取手机验证码
 */
export const getSendCodeAPI = (phone, type = "4") => {
  return request({
    url: "/account/getSmsCode",
    params: {
      phone,
      type,
    },
  });
};

/**
 * 验证码登录
 */
export const postVerificationLoginAPI = (phone, smsCode) => {
  return request({
    url: "/account/smsLogin",
    method: "post",
    data: {
      phone,
      smsCode,
      type: "4",
    },
  });
};

/**
 * 修改密码
 */
export const postEditPwdAPI = (data) => {
  return request({
    url: "/account/modifyPwd",
    method: "post",
    data,
  });
};

/**
 * 忘记密码
 */
export const postForgetPwdAPI = (data) => {
  return request({
    url: "/account/forgotPwd",
    method: "post",
    data,
  });
};

/**
 * 退出登录
 */
export const postLogOutAPI = () => {
  return request({
    url: "/account/logout",
    method: "post",
  });
};
