<template>
  <div id="app">
    <router-view />
    <global-footer></global-footer>
  </div>
</template>

<script>
import GlobalFooter from "@/components/base/globalFooter/GlobalFooter.vue";
import wx from "weixin-js-sdk";
export default {
  name: "App",
  components: {
    // HelloWorld
    GlobalFooter,
  },
  async created() {
    let that = this;
    let ua = navigator.userAgent.toLocaleLowerCase();
    let isWeixin = ua.indexOf("micromessenger") !== -1;
    window.console.log("ua==>", ua);
    if (isWeixin) {
      await wx.miniProgram.getEnv(function (res) {
        window.console.log("wx.miniPorgram", res.miniprogram);
        //判断是否小程序环境
        if (res.miniprogram == true) {
          that.$store.commit("isWeixin", true);
        } else {
          that.$store.commit("isWeixin", false);
        }
      });
    }
  },
};
</script>

<style>
/*#app {*/
/*    font-family: Avenir, Helvetica, Arial, sans-serif;*/
/*    -webkit-font-smoothing: antialiased;*/
/*    -moz-osx-font-smoothing: grayscale;*/
/*    text-align: center;*/
/*    color: #2c3e50;*/
/*    font-size: 14px;*/
/* height: 100%; */
/* margin-top: 60px; */
/*}*/
</style>
