import Vue from "vue";
import router from "./router";
import store from "./store";
import Storage from "vue-ls";
import { axios } from "@/utils/http";
// Vant样式
import "vant/lib/index.css";
//
import "@/plugins/vant";

// 根节点
import App from "./App.vue";
// 全局样式
import "@/assets/style/common.scss";

// 全局 SvgIcon 组件
import "@/assets/icons";
import "amfe-flexible";
import wx from "weixin-js-sdk";
// 导入公共指令
import "@/directives/index";

Vue.config.productionTip = false;

const options = {
  namespace: "MOBILE_", // 移动端
  name: "ls",
  storage: "local",
};
Vue.use(Storage, options);

Vue.prototype.$axios = axios;
Vue.prototype.$wx = wx;
import "leaflet/dist/leaflet.css";

// import VConsole from "vconsole";
// if (process.env.NODE_ENV == "development") {
//   new VConsole();
// }

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
