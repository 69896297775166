import Vue from "vue";
import axios from "axios";
import errorHandle from "./errorHandle";
import store from "@/store/index";
import $router from "@/router/index";
import { REMOVE_TOKEN, GET_TOKEN, SET_TOKEN } from "@/utils/token";
import { REMOVE_USERINFO } from "@/utils/userInfo";
import { Notify } from "vant";
import wx from "weixin-js-sdk";
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // 环境变量 基地址
  timeout: 30000, // 超时时间
  // 请求参数
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
  withCredentials: true, // 允许携带cookie
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // const token = Vue.ls.get("ACCESS_TOKEN");
    // token && (config.headers["Authorization"] = "Bearer " + token);
    // if (
    //   token &&
    //   (store.state.user.userInfo === null ||
    //     JSON.stringify(store.state.user.userInfo) === {})
    // ) {
    //   const userInfo = Vue.ls.get("USER_INFO");
    //   userInfo && store.dispatch("setUserInfoAction", userInfo);
    // }

    const token = store.state.user.token || GET_TOKEN();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => Promise.error(new Error(err))
);

// 响应拦截器
instance.interceptors.response.use(
  (res) => {
    if (res.status !== 200) {
      errorHandle(res);
    } else {
      let code = parseInt(res.data.code);
      let isminiProgram = store.state.app.isWeixin;
      switch (code) {
        case 401:
          // 删除Token和用户信息
          REMOVE_TOKEN();
          REMOVE_USERINFO();
          window.localStorage.setItem("GangXi_Tour_H5_USERINFO", null);
          //TODO 通用方法环境加载
          // eslint-disable-next-line no-console
          console.log("isminiProgram=>", isminiProgram);
          // eslint-disable-next-line no-console
          if (isminiProgram) {
            Notify({ type: "danger", message: res.data.msg });
            setTimeout(() => {
              wx.miniProgram.reLaunch({
                url: "/pages/login/login?token=false",
              });
            }, 1000);
          } else {
            $router.push({
              path: "/login",
              query: {
                redirect:
                  location.pathname.replace(
                    process.env.VUE_APP_CONTEXT_PATH,
                    ""
                  ) + location.search,
              },
            });
          }

          break;
        case 500:
          Notify({ type: "danger", message: res.data.msg });
          break;
        default:
          break;
      }
    }
    // return Promise.resolve(res.data);

    return res.data;
  },
  (err) => {
    errorHandle(err);
    return Promise.reject(err);
  }
);

export default instance;
