// 当前定位信息存储
const location = {
    state: {
        location: {}, // 具体地址
        point: "" // 经纬度信息
    },
    mutations: {
        setLocation(state, params) {
            state.location = params;
        },
        setPoint(state, params) {
            state.point = params;
        }
    },
    actions: {
        setLocationAction({ commit }, params) {
            commit("setLocation", params);
        },
        setPointAction({ commit }, params) {
            commit("setPoint", params);
        }
    },
    getters: {}
};

export default location;
