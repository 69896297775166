// 景区农庄路由

export default [
  {
    path: "/besideFeature",
    name: "besideFeature",
    meta: {
      title: "景区农庄",
      requiresAuth: false
    },
    component: () => import("@/views/besideFeature/index.vue")
  },

  {
    path: "/FeatureDetail",
    name: "FeatureDetail",
    meta: {
      title: "详情",
      requiresAuth: false
    },
    component: () => import("@/views/besideFeature/FeatureDetail")
  },

  {
    path: "/projectDetail",
    name: "projectDetail",
    meta: {
      title: "项目详情",
      requiresAuth: false
    },
    component: () => import("@/views/besideFeature/projectDetail.vue")
  },

  {
    path: "/ticketAdvancePay",
    name: "ticketAdvancePay",
    meta: {
      title: "门票订单",
      requiresAuth: true
    },
    component: () => import("@/views/besideFeature/ticketAdvancePay.vue")
  },

  {
    path: "/ticketOrder",
    name: "ticketOrder",
    meta: {
      title: "订单详情",
      requiresAuth: true
    },
    component: () => import("@/views/besideFeature/ticketOrder.vue")
  },

  {
    path: "/addPerson",
    name: "addPerson",
    meta: {
      title: "添加出行人",
      requiresAuth: false
    },
    component: () => import("@/views/besideFeature/addPerson.vue")
  }
];
