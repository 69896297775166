// 地图路由

export default [
  {
    path: "/address",
    name: "address",
    meta: {
      // title: "新增地址",
      requiresAuth: true
    },
    component: () => import("@/views/address/address.vue")
  },
  {
    path: "/addressList",
    name: "addressList",
    meta: {
      title: "地址列表",
      requiresAuth: true
    },
    component: () => import("@/views/address/addressList.vue")
  }
];
