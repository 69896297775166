import Vue from "vue";
import store from "@/store";
import VueRouter from "vue-router";
import { routes } from "./routes";
import { isIOS } from "@/utils/util";
import { GET_TOKEN, SET_TOKEN, REMOVE_TOKEN } from "@/utils/token";
import { GET_USERINFO } from "@/utils/userInfo";
import wx from "weixin-js-sdk";
// 页面加载显示的导航条组件
import NProgress from "nprogress";
import "nprogress/nprogress.css";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_CONTEXT_PATH,
  routes,
});

// 白名单列表
// const whiteList = [];

// router.beforeEach(async (to, from, next) => {
//   const token = GET_TOKEN();
//   if (to.meta.requireAuth) {
//     if (token) {
//       next();
//     } else {
//       next({
//         path: "/login",
//         query: { redirect: to.fullPath }
//       });
//     }
//   } else {
//     if (isIOS() && window.iosInitUrl === undefined) {
//       window.iosInitUrl = window.location.href.split("#")[0];
//     }
//     next();
//   }

//   if (to.meta.title) {
//     document.title = to.meta.title || "首页";
//   }

//   // 防刷新清空用户信息
//   if (
//     store.getters.token === "" &&
//     JSON.stringify(store.getters.userInfo) === "{}"
//   ) {
//     const token = GET_TOKEN();
//     const userInfo = GET_USERINFO();
//     if (
//       token &&
//       token !== "" &&
//       userInfo &&
//       JSON.stringify(userInfo) !== "{}"
//     ) {
//       store.commit("setToken", token);
//       store.dispatch("setUserInfoAction", userInfo);
//     }
//   }
// });

// 加载中小圆圈
// NProgress.configure({ showSpinner: false });

router.beforeEach((to, from, next) => {
  // 在页面加载前开启导航条
  NProgress.start();
  // eslint-disable-next-line no-console
  console.log("to==>", to);
  // eslint-disable-next-line no-console
  console.log("from==>", from);
  let wtoken = to.query.token;
  window.console.log("www", wtoken, "type", typeof wtoken);
  // if (
  //   wtoken != "null" &&
  //   wtoken != "" &&
  //   wtoken != undefined &&
  //   wtoken != null
  // ) {
  //   SET_TOKEN(wtoken);
  // }

  if (wtoken == "null") {
    REMOVE_TOKEN();
  } else {
    wtoken && SET_TOKEN(wtoken);
  }

  // 获取本地存储的Token
  const token = GET_TOKEN();
  // eslint-disable-next-line no-console
  console.log("webtk", token);
  window.document.title = to.meta.title;

  if (to.meta.requiresAuth) {
    // 如果去的页面需要鉴权
    // 如果Token存在且未过期
    if (token) {
      next();
    } else if (!token) {
      // TODO 全局路由导航 兼容小程序 跳到登录页面
      // Token不存在或已过期，重定向到登录页面
      let isminiProgram = store.state.app.isWeixin;
      // eslint-disable-next-line no-console
      console.log("sss+>", isminiProgram);
      if (isminiProgram) {
        wx.miniProgram.switchTab({
          url: "/pages/my/my",
        });
      } else {
        next({
          path: "/login",
          query: { redirect: to.fullPath },
        });
      }
    }
  } else {
    // 页面不需要鉴权，直接放行
    next();
  }
});

router.afterEach((to, from, next) => {
  // 关闭导航条
  NProgress.done();
});

Vue.use(VueRouter);

export default router;
