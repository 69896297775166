// 本地存储读取Token的方法

// 设置过期时间为24小时后
// const expiry = new Date();
// expiry.setDate(expiry.getDate() + 1);

// 存
export const SET_TOKEN = (token) => {
  // 把Token和过期时间保存到本地存储
  localStorage.setItem(
    "GangXi_Tour_H5_TOKEN",
    // JSON.stringify({ token, expiry })
    token
  );
};

// 读
export const GET_TOKEN = () => {
  let value = localStorage.getItem("GangXi_Tour_H5_TOKEN");
  if (
    value == undefined ||
    value === "undefined" ||
    value == "null" ||
    value == null
  ) {
    return false;
  } else {
    return localStorage.getItem("GangXi_Tour_H5_TOKEN");
  }
};

// 删
export const REMOVE_TOKEN = () => {
  return localStorage.removeItem("GangXi_Tour_H5_TOKEN");
};
