// 封装指令模块
import Vue from "vue";

// 封装默认图片
// import defaultAvatar from "../assets/img/image-error.jpg";
import defaultAvatar from "../assets/img/imgerr.png";
// let defaultAvatar = require("@/assets/img/imgerr.png");

Vue.directive("imagerror", {
  inserted(el, binding) {
    window.console.log(typeof binding.value);
    let isvalue =
      binding.value === null ||
      binding.value === "null" ||
      binding.value === "" ||
      binding.value === undefined;
    if (isvalue) {
      el.src = defaultAvatar;
    }
    // el.onerror = function () {
    //   el.src = binding.value || defaultAvatar;
    // };
  },

  // 当指令所在组件更新时
  // update(el, binding) {
  //   el.src = binding.value || defaultAvatar; //如果图片src不存在或者为空，就将图片的src替换为自定义指令传入的值
  // }
});
