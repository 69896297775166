// 伴手好礼
export default [
  // 商品列表页面
  {
    path: "/souvenir/list",
    name: "giftList",
    meta: {
      title: "伴手好礼", // 用于设置路由对应页面的标题
      requiresAuth: false, // 用户路由控制
    },
    component: () => import("@/views/departingGift/giftList.vue"),
  },

  // 商家列表页面
  {
    path: "/souvenir/shopList",
    name: "shopList",
    meta: {
      title: "店铺列表",
      requiresAuth: false,
    },
    component: () => import("@/views/departingGift/shopList.vue"),
  },
  {
    path: "/souvenir/shopDetail",
    name: "shopDetail",
    meta: {
      title: "店铺详情",
      requiresAuth: false,
    },
    component: () => import("@/views/departingGift/shopDetail.vue"),
  },
  {
    path: "/souvenir/giftDetail",
    name: "giftDetail",
    meta: {
      title: "商品详情",
      requiresAuth: false,
    },
    component: () => import("@/views/departingGift/giftDetail.vue"),
  },
  {
    path: "/souvenir/orderAdvancePay",
    name: "orderAdvancePay",
    meta: {
      title: "商品订单",
      requiresAuth: true,
    },
    component: () => import("@/views/departingGift/orderAdvancePay.vue"),
  },
  {
    path: "/souvenir/have-pay",
    name: "have-pay",
    meta: {
      title: "下单成功",
      requiresAuth: true,
    },
    component: () => import("@/views/departingGift/havePay.vue"),
  },
  {
    path: "/souvenir/orderDetail",
    name: "orderDetail",
    meta: {
      title: "订单详情",
      requiresAuth: true,
    },
    component: () => import("@/views/departingGift/orderDetail.vue"),
  },
  {
    path: "/souvenir/afterService",
    name: "afterService",
    meta: {
      title: "售后",
      requiresAuth: true,
    },
    component: () => import("@/views/departingGift/afterService.vue"),
  },
  {
    path: "/shoppingCar/index",
    name: "shoppingCar",
    meta: {
      title: "购物车",
      requiresAuth: false,
    },
    component: () => import("@/views/shoppingCar/index.vue"),
  },
  {
    path: "/souvenir/confirmOrder",
    name: "confirmOrder",
    meta: {
      title: "确认订单",
      requiresAuth: true,
    },
    component: () => import("@/views/departingGift/confirmOrder.vue"),
  },
];
