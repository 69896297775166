export default [
  // 特色周边
  {
    path: "/scenicArea",
    name: "scenicArea",
    meta: {
      title: "特色周边",
      requiresAuth: false
    },
    component: () => import("@/views/scenicArea/index.vue")
  },

  // 特色周边详情
  {
    path: "/PeripheralDetail",
    name: "PeripheralDetail",
    meta: {
      title: "详情",
      requiresAuth: false
    },
    component: () => import("@/views/scenicArea/peripheralDetail")
  }
];
