// 本地存储读取用户信息的方法

// 存
export const SET_USERINFO = (userInfo) => {
  localStorage.setItem("GangXi_Tour_H5_USERINFO", JSON.stringify(userInfo));
};

// 读
export const GET_USERINFO = () => {
  return localStorage.getItem("GangXi_Tour_H5_USERINFO");
};

// 删
export const REMOVE_USERINFO = () => {
  return localStorage.removeItem("GangXi_Tour_H5_USERINFO");
};
