// 当前定位信息存储
const order = {
  // 命名空间
  namespaced: true,
  state: {
    giftInfo: {} // 伴手好礼商品对象
  },

  mutations: {
    saveGiftInfo(state, params) {
      state.giftInfo = params;
    }
  },

  actions: {
    setGiftInfo({ commit }, params) {
      commit("saveGiftInfo", params);
    }
  }
};

export default order;
