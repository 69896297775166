import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import * as modules from "./modules";

Vue.use(Vuex);

const strict = process.env.ENV_MODE === "development";

const store = new Vuex.Store({
  modules,
  strict,
  getters
});

if (module.hot) {
  module.hot.accept(
    [
      "./modules/app",
      "./modules/user",
      "./modules/location",
      "./modules/order.js"
    ],
    () => {
      module.hotUpdate({
        modules: {
          app: require("./modules/app"),
          user: require("./modules/user"),
          location: require("./modules/location"),
          order: require("./modules/order")
        }
      });
    }
  );
}

export default store;
