// 我的模块路由
export default [
  // 我的评论列表
  {
    path: "/myComment",
    name: "myComment",
    meta: {
      title: "我的评论",
      requiresAuth: true,
    },
    component: () => import("@/views/user/myComment.vue"),
  },
  {
    path: "/myComment/details",
    name: "myCommentDetails",
    meta: {
      title: "评论详情",
      requiresAuth: true,
    },
    component: () => import("@/views/user/myCommentDetails.vue"),
  },
];
